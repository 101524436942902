<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction, getParams } from '@/command/netTool'
import moment from 'moment'
import Big from 'big.js'
export default {
  name: 'platform',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getPlatformOrderPage',
      params: { current: 1, pageSize: 10 },
      paramsValue: {},
      dateList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getPlatformOrderTotal', params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let orderCount = 0
      let totalAmount = 0
      let productQuantity = 0
      let productQuantityCompleted = 0
      let totalAmountCompleted = 0
      let refundCount = 0
      let salesJsAmount = 0
      let refundAmount = 0
      let feeAmount = 0
      list.forEach((e) => {
        orderCount = parseFloat(new Big(e.orderCount || 0).plus(orderCount))
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        productQuantity = parseFloat(new Big(e.productQuantity || 0).plus(productQuantity))
        productQuantityCompleted = parseFloat(new Big(e.productQuantityCompleted || 0).plus(productQuantityCompleted))
        totalAmountCompleted = parseFloat(new Big(e.totalAmountCompleted || 0).plus(totalAmountCompleted))
        refundCount = parseFloat(new Big(e.refundCount || 0).plus(refundCount))
        salesJsAmount = parseFloat(new Big(e.salesJsAmount || 0).plus(salesJsAmount))
        refundAmount = parseFloat(new Big(e.refundAmount || 0).plus(refundAmount))
        feeAmount = parseFloat(new Big(e.feeAmount || 0).plus(feeAmount))
      })
      return {
        createTime: '合计',
        orderCount,
        totalAmount,
        productQuantity,
        productQuantityCompleted,
        totalAmountCompleted,
        refundCount,
        salesJsAmount,
        refundAmount,
        feeAmount,
        type: '1',
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getPlatformOrderPage', params).then((e) => {
          this.records = e.data['records'].map((e) => ({ ...e, dateTypeQuery: params.dateTypeQuery }))
          this.params = { ...params }
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            this.records.push(this.getSum(e.data['records']))
            this.records.push({
              ...total,
              orderCount: total.orderCount,
              totalAmount: total.totalAmount,
              feeAmount:total.feeAmount,
              createTime: '总计',
              type: '1',
            })
          }
        })
      })
    },
    getHeader() {
      return [
        ,
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
          props: {
            allowClear: true,
          },
        },
        {
          type: 'select',
          name: '产品类型',
          key: 'fromType',
          typeData: [
            {
              name: '酒店民宿',
              value: '13',
            },
            {
              name: '景点门票',
              value: '12',
            },
            {
              name: '农家乐',
              value: '2',
            },
            {
              name: '农事体验',
              value: '4',
            },
            // {
            //   name: '组合套餐',
            //   value: '14',
            // },
            {
              name: '农家特产',
              value: '1',
            },
          ],
        },
        {
          type: 'priceRange',
          name: '订单金额',
          keys: ['totalAmountGe', 'totalAmountLe'],
        },
        {
          type: 'priceRange',
          name: '核销金额',
          keys: ['totalAmountCompletedGe', 'totalAmountCompletedLe'],
        },
        {
          type: 'priceRange',
          name: '订单人数',
          keys: ['orderCountGe', 'orderCountLe'],
        },
        {
          type: 'priceRange',
          name: '核销人数',
          keys: ['productQuantityCompletedGe', 'productQuantityCompletedLe'],
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '时间',
          dataIndex: 'createTime',
        },

        {
          title: '订单数',
          dataIndex: 'orderCount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.orderCount - b.orderCount
            }
          },
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmount - b.totalAmount
            }
          },
        },
         {
          title: '退款数量',
          dataIndex: 'refundCount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundCount - b.refundCount
            }
          },
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundAmount - b.refundAmount
            }
          },
        },
         {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.productQuantityCompleted - b.productQuantityCompleted
            }
          },
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmountCompleted - b.totalAmountCompleted
            }
          },
          customRender: (text) => {
            return <span>{parseFloat(text).toFixed(2)}</span>
          },
        },
         {
          title: '手续费',
          dataIndex: 'feeAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.feeAmount - b.feeAmount
            }
          },
        },
        {
          title: '结算金额',
          dataIndex: 'salesJsAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.salesJsAmount - b.salesJsAmount
            }
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            if (records.createTime == '合计' || records.createTime == '总计') {
              return []
            }

            return [
              {
                name: '详情',
                onClick: () => {
                  switch (records.dateTypeQuery) {
                    case '0':
                      this.$router.push(
                        `/dataReport/platformDetail?dateTypeQuery=${records.dateTypeQuery}&dateGe=${moment(
                          records.createTime
                        ).format('YYYY-MM-DD')}&dateLe=${moment(records.createTime).format('YYYY-MM-DD')}&fromType=${
                          this.params.fromType || ''
                        }`
                      )
                      break
                    case '1':
                      this.$router.push(
                        `/dataReport/platformDetail?dateTypeQuery=${records.dateTypeQuery}&dateMonthGe=${moment(
                          records.createTime
                        )
                          .startOf('week')
                          .format('YYYY-MM-DD')}&dateMonthLe=${moment(records.createTime)
                          .endOf('week')
                          .format('YYYY-MM-DD')}&fromType=${this.params.fromType || ''}`
                      )
                      break
                    case '2':
                      this.$router.push(
                        `/dataReport/platformDetail?dateTypeQuery=${records.dateTypeQuery}&dateYearStr=${moment(
                          records.createTime
                        ).format('YYYY')}&fromType=${this.params.fromType || ''}
                        `
                      )
                      break
                    default:
                      this.$router.push(
                        `/dataReport/platformDetail?dateTypeQuery=0&dateGe=${moment(records.createTime).format(
                          'YYYY-MM-DD'
                        )}&dateLe=${moment(records.createTime).format('YYYY-MM-DD')}&fromType=${
                          this.params.fromType || ''
                        }`
                      )
                      break
                  }
                },
              },
            ]
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const {
              fromType,
              dateGe,
              dateLe,
              orderCountGe,
              orderCountLe,
              productQuantityCompletedGe,
              productQuantityCompletedLe,
              totalAmountCompletedGe,
              totalAmountCompletedLe,
              totalAmountGe,
              totalAmountLe,
            } = this.params

            downloadAction(
              `/api/reportForm/exportPlatformOrder?fromType=${fromType || ''}&dateGe=${dateGe || ''}&dateLe=${
                dateLe || ''
              }&orderCountGe=${orderCountGe || ''}&orderCountLe=${orderCountLe || ''}&productQuantityCompletedGe=${
                productQuantityCompletedGe || ''
              }&productQuantityCompletedLe=${productQuantityCompletedLe || ''}&totalAmountCompletedGe=${
                totalAmountCompletedGe || ''
              }&totalAmountCompletedLe=${totalAmountCompletedLe || ''}&totalAmountGe=${
                totalAmountGe || ''
              }&totalAmountLe=${totalAmountLe || ''}&dateList=${this.dateList.length ? this.dateList : ''}`,
              '平台报表.xlsx'
            )
          },
        },
      ]
    },
    selectRow(data, records) {
      this.dateList = records.map((d) => d.createTime)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
